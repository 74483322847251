import React from "react";
import { Grid } from "@mui/material";
import { service_data } from "../../utils/mockData";
import "./index.css";
import ServiceCard from "./ServiceCard";

const ServiceMain = () => {
  return (
    <div className="w-full">
      <div className="services_banner h-[80vh] flex flex-col justify-center items-center">
        <span
          className="text-[3rem] font-bold text-[#fff] text-start"
          style={{ transform: "translateY(120px)" }}
        >
          Our Services
        </span>

        {/* <span className="pt-[5px] font-bold text-[1.7rem]">What we do</span> */}
      </div>

      <div
        className="my-[1rem]"
        style={{ transform: "translateY(-100px)", padding: "0 30px" }}
      >
        <ServiceCard message="8667632458" title="Call Us Today" />
      </div>

      <div className="p-[30px] text-left">
        <span className="font-bold text-[2rem] text-[#000]">What we do</span>
        <span className="elementor-divider-separator mb-[2px]"> </span>
      </div>

      <div className="my-[1rem] p-[30px] service_cards">
        <Grid container spacing={2}>
          {service_data.map((items, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                alignItems: "start",
                margin: "10px 0px",
              }}
              className="flex justify-start flex-col items-start "
            >
              <div className="flex flex-col justify-start items-center bg-[#ffffffe3] card mx-[10px] my-[10px] h-[250px] rounded-lg">
                <div
                  className="p-[20px] icon_bg heading text-center rounded-[50%] boxShadow"
                  style={{ transform: "translateY(-40px)" }}
                >
                  {items.icon}
                </div>
                <div className="flex flex-col justify-center items-start  heading p-[20px]">
                  <span className="text-start font-bold text-[1.125rem]  heading mb-[6px]">
                    {items.name}
                  </span>
                  <span className="text-start font-medium text-[0.8rem]  content">
                    {items.description}
                  </span>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default ServiceMain;
