import React from "react";

import { Box, Button, Grid } from "@mui/material";
import { service_data } from "../../utils/mockData";
import ReusableButton from "../../components/button";
import "./index.css";
import { AnimatedButton } from "../../components/animatedButton";
import { useNavigate } from "react-router";
import { IconSettings } from "@tabler/icons-react";

const Service = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full p-[30px]">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div className="flex flex-col justify-start items-start">
            <span className="text-[1rem] font-bold title text-start">
              OUR SERVICES
            </span>

            <span className="pt-[5px] font-bold text-[1.8rem] heading">
              What we do
            </span>

            <span className="elementor-divider-separator mb-[2px]"></span>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            margin: { xs: "0.5rem auto 0 0", sm: "16px auto 0 3rem" },
            paddingTop: { xs: "0", sm: "1rem" },
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            alignItems: "start",
          }}
          className="flex justify-start flex-col items-start"
        >
          {service_data.slice(0, 2).map((items, index) => (
            <Box
              sx={{ marginBottom: { xs: "0.5rem", sm: "2rem" } }}
              className="flex justify-start card items-start"
              key={index}
            >
              <Box
                className="p-[18px] icon_bg text-center rounded-[50%] boxShadow"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                {items.icon}
              </Box>
              <div className="flex flex-col justify-center items-start  text-[#678983]  ml-[30px]">
                <span className="text-start font-bold text-[1.25rem]  title mb-[6px]">
                  {items.name}
                </span>
                <span className="text-start font-medium text-[1rem] content">
                  {items.description}
                </span>
              </div>
            </Box>
          ))}

          <Box
            className=""
            sx={{ padding: { xs: "0.5rem", sm: "1.5rem", md: "3rem" } }}
          >
            <AnimatedButton
              buttonName="Know More"
              onClick={() => navigate("/services")}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Service;
