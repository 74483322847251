import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Mousewheel,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Home from "../screens/home/index";
import Service from "../screens/service/index";
import About from "../screens/about/index";
import Contact from "../screens/contact/index";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./styles.css";

// Import Swiper styles for vertical mode
import "swiper/css/effect-fade";
import SpeedDial from "../components/speedDial";
import Benefits from "../screens/Benefits";

const Slide = () => {
  const swiperRef = useRef(null);
  const currentSlideIndex = useSelector((state) => state.slide.index);
  console.log(currentSlideIndex, "currentSlideIndex");

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(currentSlideIndex);
    }
  }, [currentSlideIndex]);

  return (
    <div style={{ height: "88vh" }}>
      <Swiper
        className="swiper_head"
        modules={[Navigation, Pagination, Scrollbar, A11y, Mousewheel]}
        direction="vertical"
        spaceBetween={30}
        slidesPerView={1}
        pagination={{ clickable: true }}
        mousewheel={{ invert: false }}
        speed={1000}
        style={{ height: "100%" }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Home />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Service />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Benefits />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <About />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Contact />
        </SwiperSlide>
      </Swiper>

      <div>
        <SpeedDial />
      </div>
    </div>
  );
};

export default Slide;
