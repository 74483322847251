import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/layout";
import Slide from "../components/slide";
import ServiceMain from "../screens/service/serviceMain";
import AboutMain from "../screens/about/aboutMain";
import Orders from "../screens/orders";

const index = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Slide />} />
          <Route exact path="/services" element={<ServiceMain />} />
          <Route exact path="/about" element={<AboutMain />} />
          <Route exact path="/orders" element={<Orders />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default index;
