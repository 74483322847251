import { Box, Grid } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { TypeAnimation } from "react-type-animation";
import { AnimatedButton } from "../../components/animatedButton";
import { useNavigate } from "react-router";
import "./index.css";
import HomeImg from "../../images/24644990-removebg-preview.png";
import { IconArrowBadgeRight } from "@tabler/icons-react";
import { IconPaw } from "@tabler/icons-react";
import { IconRosetteDiscount } from "@tabler/icons-react";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="bg-cover w-full bg-no-repeat bg-bottom heading pl-[30px]">
      <div className=" flex items-center ">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={7}>
            <div className="flex justify-center items-start flex-col">
              <span className="text-[1.5rem] title font-bold  pb-[8px] text-start">
                Welcome to Bharat Behemoth Pharma
              </span>
              <span className="elementor-divider-separator mb-[2px]"></span>
              <TypeAnimation
                sequence={[
                  "Unbeatable Wholesale &\nRetail Services.", // Types 'One'
                  1000,
                ]}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
                style={{
                  fontSize: "2.8rem",
                  whiteSpace: "pre-line",
                  lineHeight: "3.5rem",
                  textAlign: "start",
                }}
              />

              <div className="mt-[1rem] flex">
                <IconArrowBadgeRight
                  style={{ color: "#678983", maxWidth: "20px", height: "20px" }}
                />
                <span className="text-[0.8rem] flex title font-medium  pb-[4px] text-start ">
                  You can place retail orders via phone or social media
                  channels.
                </span>
              </div>

              <div className="flex">
                <IconArrowBadgeRight
                  style={{ color: "#678983", maxWidth: "20px", height: "20px" }}
                />
                <span className="text-[0.8rem] flex title font-medium  pb-[4px] text-start ">
                  We offer a variety of doctor-recommended medicines with 20-50%
                  discounts and free delivery.
                </span>
              </div>

              <div className="flex">
                <IconArrowBadgeRight
                  style={{ color: "#678983", maxWidth: "20px", height: "20px" }}
                />
                <span className="text-[0.8rem] flex title font-medium  pb-[4px] text-start">
                  {/* Moreover, we also supply veterinary medicines for your pets,
                available for both wholesale and retail purchases */}
                  We also offer veterinary medicines for pets{" "}
                  {/* <IconPaw
                    style={{
                      color: "#678983",
                      width: "14px",
                      height: "14px",
                      marginLeft: "2px",
                    }}
                  /> */}
                  , available in both wholesale and retail.{" "}
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={0} sm={5} sx={{ display: { xs: "none", sm: "flex" } }}>
            <div className="flex justify-center items-center">
              <img
                src={HomeImg}
                alt="home_img"
                style={{ width: "400px", height: "300px" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "2rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "space-between", sm: "start" },
                alignItems: "center",
              }}
            >
              <AnimatedButton
                buttonName="Discover More"
                onClick={() => navigate("/services")}
              />

              {/* <span className="mx-[2rem]"></span>

              <AnimatedButton
                buttonName="Order Now"
                onClick={() => navigate("/orders")}
              /> */}
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Index;
