import { configureStore } from '@reduxjs/toolkit';
import slideReducer from '../slice/slideSlice';

const store = configureStore({
    reducer: {
        slide: slideReducer
    }
});

export default store;
