import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "./index.css";
import { IconPhone } from "@tabler/icons-react";

const ServiceCard = ({ title, message }) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        backgroundColor: "#678983",
        padding: "1.6rem",
        textAlign: "start",
        borderRadius: "10px",
      }}
    >
      <CardContent className="flex flex-col">
        <span className="focus_text text-[0.75rem] font-bold flex justify-start items-center">
          <IconPhone
            stroke={1.5}
            style={{ width: "1rem", height: "1rem", margin: "0 10px 0 0" }}
          />
          {title}{" "}
        </span>
        <span className="focus_text text-[1.6rem] font-bold mt-[14px] ">
          {message}
        </span>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
