import { MantineProvider } from "@mantine/core";
import "./App.css";
import Layout from "./components/layout";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import Slide from "./components/slide";
import Routers from "./routers/index";

function App() {
  return (
    <Provider store={store}>
      <MantineProvider>
        <div className="App">
          <Routers />
        </div>
      </MantineProvider>
    </Provider>
  );
}

export default App;
