import React, { useState } from "react";
import "./styles.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import logo from "../images/logo.png";
import { useDispatch } from "react-redux";
import { setSlideIndex } from "../redux/slice/slideSlice";
import { useNavigate } from "react-router";

const drawerWidth = 240;
const navItems = ["Home", "Service", "About", "Contact"];

const Index = (props) => {
  const { window, swiperRef } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <div
      onClick={handleDrawerToggle}
      style={{ textAlign: "center" }}
      className="icon_bg h-full"
    >
      <div className="my-[1.5rem]">
        <span className="focus_text">Bharath Behemoth Pharma</span>
      </div>
      <Divider />
      <ul style={{ listStyle: "none", marginTop: "1rem" }}>
        {navItems.map((item, index) => (
          <li
            key={item}
            disablePadding
            onClick={() => handleNavClick(index)}
            className="mt-[1rem]"
          >
            <Button
              sx={{
                textAlign: "center",
                cursor: "pointer",
                color: "#E6DDC4",
                fontSize: "1rem",
                fontWeight: "700",
                fontFamily: "unset",
                borderRadius: "0",
                textTransform: "capitalize",
                "&:hover": {
                  borderBottom: "3px solid #678983",
                  borderRadius: "0",
                },
              }}
            >
              {item}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleNavClick = (index) => {
    dispatch(setSlideIndex(index));
    navigate("/");
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar className="flex justify-between w-full header_bg">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            <img src={logo} alt="logo" width={60} height={60} />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item, index) => (
              <Button
                key={item}
                sx={{
                  // all: "unset",
                  cursor: "pointer",
                  color: "#E6DDC4",
                  fontSize: "1rem",
                  fontWeight: "500",
                  marginRight: "2rem",
                  fontFamily: "unset",
                  borderBottom: "3px solid #181d31",
                  borderRadius: "0",
                  textTransform: "capitalize",
                  "&:hover": {
                    borderBottom: "3px solid #678983",
                    borderRadius: "0",
                  },
                }}
                onClick={() => handleNavClick(index)}
              >
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{}}>
        <Toolbar />
      </Box>
    </Box>
  );
};

export default Index;
