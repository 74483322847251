import { createSlice } from '@reduxjs/toolkit';

const slideSlice = createSlice({
    name: 'slide',
    initialState: { index: 0 },
    reducers: {
        setSlideIndex: (state, action) => {
            state.index = action.payload;
        }
    }
});

export const { setSlideIndex } = slideSlice.actions;
export default slideSlice.reducer;
