import React from "react";
import "./index.css";
import { benefits_data } from "../../utils/mockData";
import { Box, Grid } from "@mui/material";

const Benefits = () => {
  return (
    <Box
      className="w-full"
      sx={{ padding: { xs: "20px", sm: "30px", md: "30px" } }}
    >
      <section>
        <span className="pt-[5px] font-bold text-[1.8rem] heading">
          Key Benefits
        </span>
        <span className="elementor-divider-separator mb-[2px]"></span>
      </section>
      <section className="card-list xs:mx-[0.4rem] md:mx-[3rem] mt-[1rem]">
        <Grid container spacing={{ xs: 1, sm: 3, md: 4 }} sx={{ overflow: {} }}>
          {benefits_data.map((card) => (
            <Grid item xs={6} sm={6} md={4} key={card.id}>
              <Box
                className="card-item"
                sx={{ height: { xs: "80px", md: "120px" } }}
              >
                <Box
                  className="card-header"
                  sx={{
                    backgroundColor: card.color,
                    color: "#fff",
                    fontSize: { xs: "0.7rem", md: "1.25rem" },
                  }}
                >
                  {card.title}
                </Box>
                <Box
                  className="card-body text-[#666]"
                  sx={{ padding: { xs: "10px", md: "20px" } }}
                >
                  <Box
                    className="xs:text-[10px] md:text-[1rem]"
                    sx={{ fontSize: { xs: "0.6rem", md: "1rem" } }}
                  >
                    {card.description}
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </section>
    </Box>
  );
};

export default Benefits;
