import { Box, Grid, Tooltip } from "@mui/material";
import React from "react";
import { useForm } from "@mantine/form";
import { TextInput, Textarea } from "@mantine/core";
import { yupResolver } from "mantine-form-yup-resolver";
import * as yup from "yup";
import "@mantine/core/styles.css";
import "./index.css";
import ContactImg from "../../images/12983846_5114855.jpg";
import ReusableButton from "../../components/button";

const SlideTwo = () => {
  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Invalid name")
      .min(2, "Name should have at least 2 letters"),
    email: yup.string().required("Invalid email").email("Invalid email"),
    message: yup
      .string()
      .required("Invalid message")
      .min(5, "Message should have at least 5 characters"),
  });

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validate: yupResolver(schema),
  });
  return (
    <div className="w-full p-[20px] text-start bg-[#fff]">
      <Grid container spacing={2} className="">
        <Grid item xs={12}>
          <div className="flex flex-col justify-start items-start">
            <span className="text-[1.5rem] font-bold pt-[10px] heading">
              Get In Touch With Us
            </span>
            <span
              className="elementor-divider-separator mb-[2px]"
              style={{ marginTop: "10px" }}
            ></span>
          </div>
        </Grid>
        <Grid item xs={0} sm={6} sx={{ display: { xs: "none", sm: "flex" } }}>
          <div className="w-full flex justify-center items-center">
            <img
              src={ContactImg}
              className="rounded-lg"
              alt="contact_img"
              style={{ width: "400px", height: "400px" }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ margin: { xs: "0", sm: "15px auto" }, background: "#" }}
        >
          <Box
            className="bg-[#fff] p-[2rem] rounded-[20px]  boxShadow"
            sx={{ margin: { xs: "0 20px", sm: "0 60px" } }}
          >
            <form onSubmit={form.onSubmit(console.log)}>
              <TextInput
                withAsterisk
                placeholder="Enter Your Name"
                key={form.key("name")}
                {...form.getInputProps("name")}
                className="input_fields"
              />
              <TextInput
                withAsterisk
                placeholder="Enter Your Email"
                className="mt-[1.6rem] input_fields"
                key={form.key("email")}
                {...form.getInputProps("email")}
              />
              <Textarea
                withAsterisk
                placeholder="Enter Message"
                autosize
                minRows={3}
                maxRows={5}
                className="mt-[1.6rem] input_fields"
                key={form.key("message")}
                {...form.getInputProps("message")}
              />
              <ReusableButton
                type="submit"
                className="mt-[1.5rem]"
                style={{
                  width: "100%",
                  color: "#fff",
                  background: "#678983",
                  textTransform: "capitalize",
                  margin: "1.5rem 0 0 0",
                  fontWeight: "700",
                }}
                buttonName="Send Message"
              />
            </form>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SlideTwo;
