import React from "react";
import ServiceCard from "../service/ServiceCard";
import "./index.css";
import { Grid } from "@mui/material";
import { about_data, service_data } from "../../utils/mockData";
import AboutUsPic from "../../images/13560779_5201111.jpg";

const AboutMain = () => {
  return (
    <div className="w-full">
      <div className="about_banner h-[80vh] flex flex-col justify-center items-center">
        <span
          className="text-[3rem] font-bold text-[#fff] text-start"
          style={{ transform: "translateY(120px)" }}
        >
          About Us
        </span>
        {/* <span className="pt-[5px] font-bold text-[1.7rem]">A Few Words About Us</span> */}
      </div>

      <div
        className="my-[1rem]"
        style={{ transform: "translateY(-100px)", padding: "0 30px" }}
      >
        <ServiceCard message="8667632458" title="Call Us Today" />
      </div>

      <div className="px-[30px] text-start w-full mb-[3rem]">
        <span className="font-bold text-[2rem] title text-start w-full">
          Who We Are
        </span>
        <span className="elementor-divider-separator mb-[2px]"> </span>
      </div>

      <div className="content_border mx-[30px] my-[1rem] py-[2rem] px-[30px]">
        <span className="font-bold text-[1rem] content text-start w-full">
          We are a certified wholesaler and retailer dedicated to distributing
          affordable, high-quality pharmaceuticals. Our mission is to ensure a
          dependable and efficient supply of medications to pharmacies,
          hospitals, and clinics as well as individual customers.
        </span>
      </div>

      <div className="px-[30px] text-start w-full mt-[3rem] mb-[3rem]">
        <span className="font-bold text-[2rem] title text-start w-full">
          Why Choose Us
        </span>
        <span className="elementor-divider-separator mb-[2px]"> </span>
      </div>

      <div className="content_border mx-[30px] mt-[1rem] mb-[6rem] py-[2rem] px-[30px]">
        <span className="font-bold text-[1rem] content text-start w-full">
          We offer a wide range of pharmaceutical products sourced directly from
          reputable manufacturers. Our inventory is constantly updated to ensure
          we meet the evolving needs of our customers.
        </span>
      </div>

      <div className="my-[5rem] p-[30px] aboutcard_banner">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="mx-[2rem] " style={{ transform: "scale(0.9)" }}>
              <img
                src={AboutUsPic}
                alt={AboutUsPic}
                className="rounded-[20px] boxShadow_about"
                style={{ transform: "translateY(-90px)" }}
              />
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              alignItems: "start",
              margin: "10px 0px",
            }}
            className="flex justify-start flex-col items-start "
          >
            {about_data.map((items, index) => (
              <div className="flex flex-col justify-start items-center bg-[#fff] card mx-[10px] my-[10px] rounded-lg ">
                <div className="flex flex-col justify-center items-start  heading p-[20px]">
                  <span className="text-start font-bold text-[1.5rem]  heading mb-[6px]">
                    {items.name}
                  </span>
                  <span className="text-start font-medium text-[0.8rem] content ">
                    {items.describe}
                  </span>
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AboutMain;
