// import React from "react";
// import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";
// import SlideTwo from "./slideTwo";
// import SlideOne from "./slideOne";

// const Contact = () => {
//   return (
//     <div style={{ width: "100%", height: "100%", marginTop: "1rem" }}>
//       <Swiper
//         modules={[Navigation, Pagination, Scrollbar, A11y]}
//         spaceBetween={30}
//         slidesPerView={1}
//         speed={1000}
//         // navigation
//         pagination={{ clickable: true }}
//         style={{ width: "100%", height: "100%" }}
//       >
//         <SwiperSlide
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "start",
//           }}
//         >
//           <SlideOne />
//         </SwiperSlide>
//         <SwiperSlide
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "start",
//           }}
//         >
//           <SlideTwo />
//         </SwiperSlide>
//       </Swiper>
//     </div>
//   );
// };

// export default Contact;

import React, { useRef, useEffect } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./index.css";
import SlideTwo from "./slideTwo";
import SlideOne from "./slideOne";
import { AnimatedButton } from "../../components/animatedButton";

const Contact = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={1}
        speed={1000}
        pagination={{ clickable: true }}
        style={{ width: "100%", height: "100%" }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <SlideOne />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <SlideTwo />
        </SwiperSlide>
        {/* <div className="custom-navigation">
          <button ref={prevRef} className="custom-prev-button">
            Prev
          </button>

          <button ref={nextRef} className="custom-next-button">
            Next
          </button>
          <AnimatedButton className="swiper-button-prev" buttonName="Prev" onClick={handlePrev} />
          <AnimatedButton className="swiper-button-next" buttonName="Next" onClick={handleNext} />
        </div> */}
      </Swiper>
    </div>
  );
};

export default Contact;
