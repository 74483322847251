import React from "react";
import { useClipboard } from "@mantine/hooks";
import { ActionIcon } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { IconButton } from "@mui/material";

// Reusable ClipboardCopyIcon component
const CopyText = ({
  textToCopy,
  copiedColor = "teal",
  defaultColor = "blue",
  timeout = 1000,
}) => {
  const clipboard = useClipboard({ timeout });

  return (
    <IconButton
      color={clipboard.copied ? copiedColor : defaultColor}
      onClick={() => clipboard.copy(textToCopy)}
    >
      {clipboard.copied ? (
        <IconCheck
          stroke={1.5}
          style={{ color: "#12b886", width: "15px", height: "15px" }}
        />
      ) : (
        <IconCopy
          stroke={1.5}
          style={{ color: "#000", width: "15px", height: "15px" }}
        />
      )}
    </IconButton>
  );
};

export default CopyText;
