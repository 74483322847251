import { Box, Grid, Tooltip } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./index.css";
import "@mantine/core/styles.css";
import { useClipboard } from "@mantine/hooks";
import { ActionIcon } from "@mantine/core";
import CopyText from "../../hooks/copyText";

const SlideOne = () => {
  const clipboard = useClipboard({ timeout: 500 });
  const AddressData = {
    name: "Address",
    street:
      " Plot No: 3/23, First floor, Syndicate P. Gopal complex, Opp. Over Bridge, Kandhampatti,",
    district: "Salem,",
    pincode: "636005",
  };

  const mobileData = [
    {
      id: 1,
      number: "8667632458",
      wp: true,
    },
    {
      id: 2,
      number: "9385664423",
      wp: false,
    },
  ];

  const emailData = {
    email_one: "bharatbehemoth24@gmail.com",
  };

  return (
    <Box
      className="w-full text-start"
      sx={{ padding: { xs: "20px", sm: "30px", md: "30px" } }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <div className="flex flex-col justify-start items-start">
            <span className="pt-[10px] text-[1rem] font-bold title text-start">
              CONTACT US
            </span>
            <span className="pt-[5px] text-[1.8rem] font-bold heading">
              We looking forward to hearing from you!
            </span>
            <span className="elementor-divider-separator mb-[2px]"></span>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={11}
          sx={{ margin: { xs: "0", sm: "16px auto 0 auto" } }}
          className="cards"
        >
          <div className="flex justify-start items-center card">
            <div className="p-[18px] icon_bg text-center rounded-[50%] boxShadow">
              <PlaceIcon sx={{ color: "#fff" }} />
            </div>
            <div className="flex flex-col justify-center items-start ml-[30px]">
              <span className="text-start font-bold text-[1.125rem] title">
                {AddressData.name}
              </span>
              <span className="text-start font-medium text-[0.8rem] content">
                {AddressData.street}
              </span>
              <span className="text-start font-medium text-[0.8rem] content">
                {AddressData.district}
              </span>
              <span className="text-start font-medium text-[0.8rem] content">
                Pincode - {AddressData.pincode}
              </span>
            </div>
          </div>

          <Box
            className="flex justify-start items-center card"
            sx={{ marginTop: { xs: "0.5rem", sm: "2rem" } }}
          >
            <div className="p-[18px] icon_bg text-center rounded-[50%] boxShadow">
              <PhoneIcon sx={{ color: "#fff" }} />
            </div>
            <div className="flex flex-col justify-center items-start ml-[30px]">
              <span className="text-start font-bold text-[1.125rem] title">
                Phone
              </span>
              {mobileData.map((items, index) => (
                <span className="text-start font-medium content text-[0.8rem] flex items-baseline justify-center">
                  {items.number}{" "}
                  {items.wp ? (
                    <Tooltip title="WhatsApp" placement="right-start">
                      <WhatsAppIcon
                        fontSize="1rem"
                        sx={{ color: "#075E54", marginLeft: "8px" }}
                      />
                    </Tooltip>
                  ) : null}
                  <CopyText textToCopy={items.number} />
                </span>
              ))}
            </div>
          </Box>

          <Box
            className="flex justify-start items-center mt-[2rem] card"
            sx={{ marginTop: { xs: "0.5rem", sm: "2rem" } }}
          >
            <div className="p-[18px] icon_bg text-center rounded-[50%] boxShadow">
              <EmailIcon sx={{ color: "#fff" }} />
            </div>
            <div className="flex flex-col justify-center items-start ml-[30px]">
              <span className="text-start font-bold text-[1.125rem] title">
                Email
              </span>
              <div>
                <span className="text-start font-medium text-[0.8rem] content">
                  {emailData.email_one}
                </span>
                <CopyText textToCopy={emailData.email_one} />
              </div>
            </div>
          </Box>
        </Grid>
        {/* <Grid item xs={12}>
          
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default SlideOne;
