import React from "react";

import { Button, Grid } from "@mui/material";
import { service_data } from "../../utils/mockData";
import ReusableButton from "../../components/button";
import "./index.css";
import { AnimatedButton } from "../../components/animatedButton";
import { useNavigate } from "react-router";

const About = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full p-[30px] text-start">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} justifyContent="start">
          <div className="flex flex-col justify-start items-start">
            <span className="text-[1rem] font-bold title text-start">
              ABOUT US
            </span>
            <span className="pt-[5px] font-bold text-[1.8rem] heading text-start">
              Trusted provider of quality pharmaceuticals for better health and
              well-being
            </span>

            <span></span>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            margin: "16px auto 0 0",
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            alignItems: "start",
          }}
          className="flex justify-start flex-col items-start pt-[1rem]"
        >
          <div className="pt-[10px]">
            <AnimatedButton
              buttonName="Read More"
              onClick={() => navigate("/about")}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
