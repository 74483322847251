import React from "react";
import ServiceCard from "../service/ServiceCard";
import "./index.css";
import { Grid } from "@mui/material";
import AboutUsPic from "../../images/13560779_5201111.jpg";
import Steps from "./stepper";

const Orders = () => {
  return (
    <div className="w-full">
      <div className="order_banner h-[80vh] flex flex-col justify-center items-center">
        <span
          className="text-[3rem] font-bold text-[#fff] text-start"
          style={{ transform: "translateY(120px)" }}
        >
          Order Now
        </span>
      </div>

      <div
        className="my-[1rem]"
        style={{ transform: "translateY(-100px)", padding: "0 30px" }}
      >
        <ServiceCard message="8667632458" title="Call Us Today" />
      </div>

      <div className="px-[30px] text-start w-full mb-[3rem]">
        <span className="font-bold text-[2rem] title text-start w-full">
          How to Order
        </span>
        <span className="elementor-divider-separator mb-[2px]"> </span>
      </div>

      <div className="content_border mx-[30px] my-[1rem] py-[2rem] px-[30px]">
        <span className="font-bold text-[1rem] content text-start w-full">
          Ordering medicines in bulk from our wholesale pharmacy is
          straightforward. Follow these steps to ensure a smooth experience
        </span>
      </div>

      <div className="px-[30px] my-[2rem] ordercard_banner">
        <Steps />
      </div>
    </div>
  );
};

export default Orders;
