import { IconSettings } from "@tabler/icons-react";
import { IconTruckDelivery } from "@tabler/icons-react";
import { IconVaccine } from "@tabler/icons-react";
import { IconHeartHandshake } from "@tabler/icons-react";
import { IconNurse } from "@tabler/icons-react";
import Fb from "../images/facebook.png";
import Insta from "../images/instagram.png";
import Youtube from "../images/youtube.png";
import WhatsApp from "../images/whatsapp.png";

export const service_data = [
  {
    id: 1,
    name: "Retail Pharmacy Services",
    description:
      "Place your order with confidence, knowing your health is our priority. Enjoy 24/7 ordering via call or WhatsApp and exciting monthly medicine discounts for unbeatable convenience and savings.",
    icon: (
      <IconSettings
        stroke={1.5}
        style={{ color: "#fff", width: "32px", height: "32px" }}
      />
    ),
  },
  {
    id: 2,
    name: "Wholesale Pharmaceutical Distribution",
    description:
      "Our wholesale division provides pharmacies, hospitals, and healthcare facilities with competitively priced medicines, ensuring prompt and reliable service.",
    icon: (
      <IconNurse
        stroke={1.5}
        style={{ color: "#fff", width: "32px", height: "32px" }}
      />
    ),
  },
  {
    id: 3,
    name: "Reliable Source for Quality Medicines",
    description:
      "We reliably provide premium pharmaceutical products with comprehensive wholesale and retail services, ensuring swift delivery through our extensive inventory and efficient supply chain.",
    icon: (
      <IconVaccine
        stroke={1.5}
        style={{ color: "#fff", width: "32px", height: "32px" }}
      />
    ),
  },
  {
    id: 4,
    name: "Fast and Reliable Delivery",
    description:
      "We prioritize timely access to medicines, striving to fulfill orders within a few days to ensure you receive your medications when needed most.",
    icon: (
      <IconTruckDelivery
        stroke={1.5}
        style={{ color: "#fff", width: "32px", height: "32px" }}
      />
    ),
  },
  {
    id: 5,
    name: "Commitment to Quality",
    description:
      "We are dedicated to providing the highest quality medicines, ensuring safety and efficacy through rigorous quality control measures and partnerships with reputable manufacturers.",
    icon: (
      <IconHeartHandshake
        stroke={1.5}
        style={{ color: "#fff", width: "32px", height: "32px" }}
      />
    ),
  },
];

export const about_data = [
  {
    id: 1,
    name: "Our Vision",
    describe:
      "Our vision is to lead in pharmaceutical distribution by providing trusted, high-quality medicines and exceptional customer service. Through strict quality control and strategic partnerships, we ensure accessible and reliable healthcare for all.",
  },
  {
    id: 2,
    name: "Our Mission",
    describe:
      "Our mission is to efficiently distribute high-quality medicines to healthcare providers and retail customers. We prioritize exceptional service, strict quality standards, and strategic partnerships, aiming to enhance healthcare outcomes and community well-being through reliability and customer satisfaction.",
  },
  {
    id: 3,
    name: "Our Motto",
    describe:
      "Enhancing Lives through Affordable and Accessible Healthcare Products. Delivering Health, Ensuring Trust.",
  },
];

export const social_media_data = [
  {
    id: 1,
    icon: Fb,
    name: "Facebook",
    link: "https://www.facebook.com/profile.php?id=61562089122404&is_tour_dismissed",
  },
  {
    id: 2,
    icon: Insta,
    name: "Instagram",
    link: "https://www.instagram.com/bharatbehemoth_pharma",
  },
  // { id: 3, icon: Youtube, name: "Youtube", link: "https://www.youtube.com" },
  {
    id: 3,
    icon: WhatsApp,
    name: "WhatsApp",
    link: "https://wa.me/918667632458",
  },
];

export const benefits_data = [
  {
    id: 1,
    title: "Convenience",
    description: "Order from the comfort of your home.",
    color: "#ffcc33",
  },
  {
    id: 2,
    title: "Privacy",
    description: "Enjoy discreet shopping for your health needs.",
    color: "#ff4d88",
  },
  {
    id: 3,
    title: "Wider Selection",
    description: "Access a broad range of products.",
    color: "#33ccff",
  },
  {
    id: 4,
    title: "Cost Efficiency",
    description: "Save money with our competitive discounts.",
    color: "#66cc66",
  },
  {
    id: 5,
    title: "24/7 Availability",
    description: "Shop anytime that suits you",
    color: "#ff3333",
  },
  {
    id: 6,
    title: "Increased Product Offering",
    description: "Find everything you need in one place.",
    color: "#cc33ff",
  },
  {
    id: 7,
    title: "Expert Guidance",
    description: "Receive advice from knowledgeable professionals.",
    color: "#3399ff",
  },
];
