import React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { social_media_data } from "../utils/mockData";

const Index = () => {
  const handleClick = (link) => {
    window.open(link, "_blank").focus();
  };
  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      className="speedDail"
      sx={{ position: "absolute", bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}
    >
      {social_media_data.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={
            <img
              src={action.icon}
              alt={action.name}
              style={{
                width: "25px",
                height: "25px",
              }}
            />
          }
          tooltipTitle={action.name}
          //   onClick={() => window.open(action.link, "_blank").focus()}
          onClick={() => handleClick(action.link)}
        />
      ))}
    </SpeedDial>
  );
};

export default Index;
