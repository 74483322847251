import { Button } from "@mui/material";
import "./styles.css";

export const AnimatedButton = ({
  size,
  style,
  endIcon,
  href,
  variant,
  maxWidth,
  fullWidth,
  onClick,
  type,
  className,
  buttonName,
  startIcon,
  disabled,
  ref,
}) => {
  return (
    <Button
      size={size}
      endIcon={endIcon}
      href={href}
      style={style}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      type={type}
      className={`animated_button  ${className}`}
      startIcon={startIcon}
      disabled={disabled}
      sx={{ transform: "capitalize" }}
      ref={ref}
    >
      <span className="button_name">{buttonName}</span>
      <svg width="15px" height="10px" viewBox="0 0 13 10">
        <path d="M1,5 L11,5"></path>
        <polyline points="8 1 12 5 8 9"></polyline>
      </svg>
    </Button>
  );
};
