import React from "react";
import { Stepper } from "@mantine/core";

const Steps = () => {
  return (
    <Stepper orientation="vertical" sx={{ background: "#f0e9d2" }}>
      <Stepper.Step label="Step 1" description="Create an account" />
      <Stepper.Step label="Step 2" description="Verify email" />
      <Stepper.Step label="Step 3" description="Get full access" />
      <Stepper.Step label="Step 3" description="Get full access" />
      <Stepper.Step label="Step 3" description="Get full access" />
    </Stepper>
  );
};

export default Steps;
